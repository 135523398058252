export default {
  methods: {
    showNotification(message, type) {
      this.$message({
        type,
        message
      })
    },
    showError(message) {
      this.$message.error(message)
    },
    showSuccess(message) {
      this.$message.success(message)
    }
  }
}
